<template>
  <div>
      <Form @submit="submit" ref="completeRegForm" v-slot="errors" class="input-fields" v-if="!registered">
        <strong
          class="Headline is-size-5 d-block"
          v-t="'BasicRegistrationTitle'"
        />
        <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" :type="text" :errors="errors"/>
        <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel"  :rules="`required|max:20`" :type="text" :errors="errors" />
        <input-select-component :fieldName="fieldNames.country" :options="countries" :fieldLabel="countryFieldLabel" />
        <div v-if="termsOfUseCheckboxRequired" class="form-checkboxes">
          <input-checkbox-component
              :fieldLabel="$t('form.terms')"
              :errors="errors.errors[fieldNames.terms]"
              :fieldName=fieldNames.terms>
            <terms-of-use-and-privacy-policy-links />
          </input-checkbox-component>
        </div>
        <button
          class="Button form-button d-block"
          :class="{ 'is-grey': !errors.meta.valid }"
          :disabled="!errors.meta.valid"
          v-text="$t(nextLabel)"
        />
      </Form>
    <p v-if="!isEdit">
      {{ $t('form.alreadyAccount') }} <link-router
        path="home"
      >{{ $t('form.loginNow') }}</link-router>
    </p>

    <regapp-feedback v-if="registered">
      <template #body>
        <div v-html="translateWithMymedelString(`${responseMessageKey}`)" />
        <div v-if="fullRegistration" v-html="translateWithMymedelString(`${responseMessageKey}`)" />
      </template>

      <template #footer>
        <br>
        <link-router
          class-names="Link d-i-block text-grey mt-1"
          path="dashboard"
        >
          {{ $t('links.toDashboard.label') }}
        </link-router>
      </template>
    </regapp-feedback>
    <sweet-modal icon="warning" ref="modalError">
      <div v-html="$t('registration.messages.internal:error')" />
    </sweet-modal>
    <validation-error-message :errors="errors" ref="validationErrorMessage" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import registrationSteps from '@/mixins/registrationForm.js'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import TermsOfUseAndPrivacyPolicyLinks from '../components/partials/TermsOfUseAndPrivacyPolicyLinks.vue'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'
import InputCheckboxComponent from '@/components/partials/InputCheckboxComponent.vue'
import { Form } from 'vee-validate'
import { unref } from 'vue'

export default {
  name: 'CompleteRegistrationProfessional',

  props: {
    submitSuccess: {
      type: Boolean,
      default: false
    },

    isEdit: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    ValidationErrorMessage,
    TermsOfUseAndPrivacyPolicyLinks,
    Form,
    InputComponent,
    InputSelectComponent,
    InputCheckboxComponent
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'countryString'
    ])
  },

  data () {
    return {
      errors: {},
      country: null,
      confirmations: {
        terms: false
      },
      registered: false,
      fullRegistration: false,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      fieldNames: {
        firstName: 'firstName',
        lastName: 'lastName',
        terms: 'terms',
        country: 'country'
      }
    }
  },

  mounted () {
    let idTokenInfo = unref(this.$auth0.idTokenClaims)
    let aadInfo = idTokenInfo['microsoft.com/aad/profile'] || {}

    let profileContainer = {}
    profileContainer['email'] = idTokenInfo['email'] ? idTokenInfo['email'] : aadInfo.upn
    profileContainer['firstName'] = idTokenInfo.given_name
    profileContainer['lastName'] = idTokenInfo.family_name
    profileContainer['country'] = idTokenInfo.countryCode

    this.language = this.$i18n.locale ? this.$i18n.locale : 'en'
    this.email = profileContainer['email']
    this.initializeFormField(this.fieldNames.firstName, profileContainer['firstName'])
    this.initializeFormField(this.fieldNames.lastName, profileContainer['lastName'])
  },

  methods: {
    ...mapActions({
      loadUserProfile: 'user/getUserFromDB',
      loadServiceClaims: 'user/getServiceClaims'
    }),
    async submit (values) {
      let profileCreated = await this.putUserProfile(this.getRequestObject(values), false)
      if (profileCreated) {
        if (this.$route.query.state) {
          window.location.replace(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/continue?state=${this.$route.query.state}`)
        } else {
          this.responseMessageKey = `registration.messages.registrationCompleted`
          this.registered = true
          this.scrollUp()
          await this.loadUserProfile()
          await this.loadServiceClaims()
        }
      }
    },

    getOptionValues (translationKey, asObject = false) {
      const entries = this.$tm(translationKey)

      return Object.keys(entries).map(key => {
        return asObject ? {
          code: key,
          label: entries[key]
        } : entries[key]
      })
    },

    getRequestObject (values) {
      return {
        registrationType: 'basic',
        responseMode: 'onComplete',
        userType: 'Professional',
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        country: values[this.fieldNames.country].code,
        agreedToTermsAndConditions: values[this.fieldNames.terms],
        dateOfRegistration: new Date(),
        email: this.email
      }
    },

    initializeFormField (fieldName, value) {
      this.$refs.completeRegForm.setFieldValue(fieldName, value)
      this.$refs.completeRegForm.validateField(fieldName, true)
    }
  }
}
</script>
